.testimonial-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial-container2 {
  gap: 24px;
  display: flex;
  padding: 16px 24px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
.testimonial-author1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.testimonial-logopolygon {
  width: 48px;
  height: 48px;
}
.testimonial-author2 {
  width: 195.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
.testimonial-text2 {
  color: var(--dl-color-default-text);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.testimonial-twitter-x {
  width: 36px;
  height: 36px;
}
.testimonial-content {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}


.testimonialroot-class-name2 {
  height: 100%;
}


.testimonialroot-class-name5 {
  height: 100%;
}


.testimonialroot-class-name8 {
  height: 100%;
}


.testimonialroot-class-name11 {
  height: 100%;
}


.testimonialroot-class-name14 {
  height: 100%;
}


.testimonialroot-class-name17 {
  height: 100%;
}


.testimonialroot-class-name20 {
  height: 100%;
}

.testimonialroot-class-name22 {
  height: auto;
}
.testimonialroot-class-name23 {
  height: 100%;
}

.testimonialroot-class-name25 {
  height: auto;
}
.testimonialroot-class-name26 {
  height: 100%;
}


.testimonialroot-class-name29 {
  height: 100%;
}


.testimonialroot-class-name32 {
  height: 100%;
}


.testimonialroot-class-name35 {
  height: 100%;
}


.testimonialroot-class-name38 {
  height: 100%;
}


.testimonialroot-class-name41 {
  height: 100%;
}


.testimonialroot-class-name44 {
  height: 100%;
}


.testimonialroot-class-name47 {
  height: 100%;
}


.testimonialroot-class-name50 {
  height: 100%;
}

.testimonialroot-class-name52 {
  height: auto;
}
.testimonialroot-class-name53 {
  height: 100%;
}

.testimonialroot-class-name55 {
  height: auto;
}
.testimonialroot-class-name56 {
  height: 100%;
}

.testimonialroot-class-name58 {
  height: auto;
}
.testimonialroot-class-name59 {
  height: 100%;
}

.testimonialroot-class-name61 {
  height: auto;
}
.testimonialroot-class-name62 {
  height: 100%;
}

.testimonialroot-class-name64 {
  height: auto;
}
.testimonialroot-class-name65 {
  height: 100%;
}

.testimonialroot-class-name67 {
  height: auto;
}
.testimonialroot-class-name68 {
  height: 100%;
}

.testimonialroot-class-name70 {
  height: auto;
}
.testimonialroot-class-name71 {
  height: 100%;
}

.testimonialroot-class-name73 {
  height: auto;
}
.testimonialroot-class-name74 {
  height: 100%;
}

.testimonialroot-class-name76 {
  height: auto;
}
.testimonialroot-class-name77 {
  height: 100%;
}

.testimonialroot-class-name79 {
  height: auto;
}
.testimonialroot-class-name80 {
  height: 100%;
}

.testimonialroot-class-name82 {
  height: auto;
}
.testimonialroot-class-name83 {
  height: 100%;
}


.testimonialroot-class-name86 {
  height: 100%;
}

.testimonialroot-class-name88 {
  height: auto;
}
.testimonialroot-class-name89 {
  height: 100%;
}
@media(max-width: 1200px) {
  .testimonial-container2 {
    width: 100%;
  }
  .testimonial-author1 {
    width: 100%;
  }
  .testimonial-author2 {
    width: auto;
  }
  .testimonial-text1 {
    width: 100%;
  }
  .testimonial-text2 {
    width: 100%;
  }
  .testimonial-content {
    width: 100%;
  }
  .testimonialroot-class-name {
    width: 100%;
  }
  .testimonialroot-class-name1 {
    width: 100%;
  }
  .testimonialroot-class-name2 {
    width: 100%;
  }
  .testimonialroot-class-name3 {
    width: 100%;
  }
  .testimonialroot-class-name4 {
    width: 100%;
  }
  .testimonialroot-class-name5 {
    width: 100%;
  }
  .testimonialroot-class-name6 {
    width: 100%;
  }
  .testimonialroot-class-name7 {
    width: 100%;
  }
  .testimonialroot-class-name8 {
    width: 100%;
  }
  .testimonialroot-class-name9 {
    width: 100%;
  }
  .testimonialroot-class-name10 {
    width: 100%;
  }
  .testimonialroot-class-name11 {
    width: 100%;
  }
  .testimonialroot-class-name12 {
    width: 100%;
  }
  .testimonialroot-class-name13 {
    width: 100%;
  }
  .testimonialroot-class-name14 {
    width: 100%;
  }
  .testimonialroot-class-name15 {
    width: 100%;
  }
  .testimonialroot-class-name16 {
    width: 100%;
  }
  .testimonialroot-class-name17 {
    width: 100%;
  }
  .testimonialroot-class-name18 {
    width: 100%;
  }
  .testimonialroot-class-name19 {
    width: 100%;
  }
  .testimonialroot-class-name20 {
    width: 100%;
  }
  .testimonialroot-class-name21 {
    width: 100%;
  }
  .testimonialroot-class-name22 {
    width: 100%;
  }
  .testimonialroot-class-name23 {
    width: 100%;
  }
  .testimonialroot-class-name24 {
    width: 100%;
  }
  .testimonialroot-class-name25 {
    width: 100%;
  }
  .testimonialroot-class-name26 {
    width: 100%;
  }
  .testimonialroot-class-name27 {
    width: 100%;
  }
  .testimonialroot-class-name28 {
    width: 100%;
  }
  .testimonialroot-class-name29 {
    width: 100%;
  }
  .testimonialroot-class-name30 {
    width: 100%;
  }
  .testimonialroot-class-name31 {
    width: 100%;
  }
  .testimonialroot-class-name32 {
    width: 100%;
  }
  .testimonialroot-class-name33 {
    width: 100%;
  }
  .testimonialroot-class-name34 {
    width: 100%;
  }
  .testimonialroot-class-name35 {
    width: 100%;
  }
  .testimonialroot-class-name36 {
    width: 100%;
  }
  .testimonialroot-class-name37 {
    width: 100%;
  }
  .testimonialroot-class-name38 {
    width: 100%;
  }
  .testimonialroot-class-name39 {
    width: 100%;
  }
  .testimonialroot-class-name40 {
    width: 100%;
  }
  .testimonialroot-class-name41 {
    width: 100%;
  }
  .testimonialroot-class-name42 {
    width: 100%;
  }
  .testimonialroot-class-name43 {
    width: 100%;
  }
  .testimonialroot-class-name44 {
    width: 100%;
  }
  .testimonialroot-class-name45 {
    width: 100%;
  }
  .testimonialroot-class-name46 {
    width: 100%;
  }
  .testimonialroot-class-name47 {
    width: 100%;
  }
  .testimonialroot-class-name48 {
    width: 100%;
  }
  .testimonialroot-class-name49 {
    width: 100%;
  }
  .testimonialroot-class-name50 {
    width: 100%;
  }
  .testimonialroot-class-name51 {
    width: 100%;
  }
  .testimonialroot-class-name52 {
    width: 100%;
  }
  .testimonialroot-class-name53 {
    width: 100%;
  }
  .testimonialroot-class-name54 {
    width: 100%;
  }
  .testimonialroot-class-name55 {
    width: 100%;
  }
  .testimonialroot-class-name56 {
    width: 100%;
  }
  .testimonialroot-class-name57 {
    width: 100%;
  }
  .testimonialroot-class-name58 {
    width: 100%;
  }
  .testimonialroot-class-name59 {
    width: 100%;
  }
  .testimonialroot-class-name60 {
    width: 100%;
  }
  .testimonialroot-class-name61 {
    width: 100%;
  }
  .testimonialroot-class-name62 {
    width: 100%;
  }
  .testimonialroot-class-name63 {
    width: 100%;
  }
  .testimonialroot-class-name64 {
    width: 100%;
  }
  .testimonialroot-class-name65 {
    width: 100%;
  }
  .testimonialroot-class-name66 {
    width: 100%;
  }
  .testimonialroot-class-name67 {
    width: 100%;
  }
  .testimonialroot-class-name68 {
    width: 100%;
  }
  .testimonialroot-class-name69 {
    width: 100%;
  }
  .testimonialroot-class-name70 {
    width: 100%;
  }
  .testimonialroot-class-name71 {
    width: 100%;
  }
  .testimonialroot-class-name72 {
    width: 100%;
  }
  .testimonialroot-class-name73 {
    width: 100%;
  }
  .testimonialroot-class-name74 {
    width: 100%;
  }
  .testimonialroot-class-name75 {
    width: 100%;
  }
  .testimonialroot-class-name76 {
    width: 100%;
  }
  .testimonialroot-class-name77 {
    width: 100%;
  }
  .testimonialroot-class-name78 {
    width: 100%;
  }
  .testimonialroot-class-name79 {
    width: 100%;
  }
  .testimonialroot-class-name80 {
    width: 100%;
  }
  .testimonialroot-class-name81 {
    width: 100%;
  }
  .testimonialroot-class-name82 {
    width: 100%;
  }
  .testimonialroot-class-name83 {
    width: 100%;
  }
  .testimonialroot-class-name84 {
    width: 100%;
  }
  .testimonialroot-class-name85 {
    width: 100%;
  }
  .testimonialroot-class-name86 {
    width: 100%;
  }
  .testimonialroot-class-name87 {
    width: 100%;
  }
  .testimonialroot-class-name88 {
    width: 100%;
  }
  .testimonialroot-class-name89 {
    width: 100%;
  }
}
@media(max-width: 550px) {
  .testimonial-content {
    width: 100%;
  }
}
