.button-secondary-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.button-secondary-secondary-button {
  cursor: pointer;
  display: flex;
  padding: 16px 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 12px 32px -20px rgba(51, 11, 129, 1) ;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: var(--dl-color-default-secondarytext);
}
.button-secondary-secondary-button:hover {
  box-shadow: 0px 16px 32px -20px #8950fa;
  background-color: #efefef;
}
.button-secondary-texts {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.button-secondary-text1 {
  color: var(--dl-color-default-maininteractive);
  width: 100%;
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.button-secondary-text2 {
  color: var(--dl-color-default-maininteractive);
  width: 100%;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.button-secondaryroot-class-name {
  max-width: 390px;
}
.button-secondaryroot-class-name1 {
  max-width: 390px;
}
.button-secondaryroot-class-name2 {
  width: auto;
}
.button-secondaryroot-class-name3 {
  width: auto;
}
@media(max-width: 550px) {
  .button-secondary-texts {
    width: 100%;
  }
  .button-secondary-text1 {
    font-family: Gotham-Bold;
  }
  .button-secondary-text2 {
    color: var(--dl-color-default-maininteractive);
    font-size: 14px;
    font-family: Gotham-Book;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;
  }
  .button-secondaryroot-class-name2 {
    width: 100%;
  }
  .button-secondaryroot-class-name3 {
    width: 100%;
  }
}
