.career-business-developer-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
.career-business-developer-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.career-business-developer-career-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.career-business-developer-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.career-business-developer-text10 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
.career-business-developer-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.career-business-developer-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text12 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text18 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text19 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text23 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text24 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text44 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text45 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text63 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.career-business-developer-text64 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .career-business-developer-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .career-business-developer-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 550px) {
  .career-business-developer-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .career-business-developer-career-hero {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: unset;
  }
  .career-business-developer-post {
    width: 100%;
  }
}
