.stakeway-highlight-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.stakeway-highlight-container2 {
  width: 100%;
  height: 64px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-default-maintext);
}
.stakeway-highlight-title {
  gap: 24px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.stakeway-highlight-text {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
.stakeway-highlightroot-class-name {
  max-width: 1200px;
}
@media(max-width: 550px) {
  .stakeway-highlight-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .stakeway-highlight-title {
    gap: var(--dl-space-space-halfunit);
  }
}
