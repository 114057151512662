.image-product-hero-container {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.image-product-hero-product-image {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
.image-product-hero-hive2 {
  width: 448px;
  height: 417px;
}
.image-product-hero-play1 {
  top: 0px;
  width: 82px;
  bottom: 0px;
  height: 82px;
  margin: auto;
  display: none;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 120px;
  background-color: rgba(255, 255, 255, 0.6000000238418579);
}
.image-product-hero-play2 {
  top: 15px;
  left: 21px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.image-product-hero-text1 {
  top: 53px;
  left: 28px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  font-style: Book;
  text-align: center;
  font-family: Gotham;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 550px) {
  .image-product-hero-hive2 {
    width: 100%;
    height: auto;
  }
}
