.button-small-secondary-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.button-small-secondary-container2 {
  gap: 8px;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  padding: 8px 12px;
  box-shadow: 0px 4px 8px -20px rgba(137, 80, 250, 0.5) ;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: var(--dl-color-default-mainbackground);
}
.button-small-secondary-container2:hover {
  background-color: #f4f4ff;
}
.button-small-secondary-text {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
  width: 100%;
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
.button-small-secondaryroot-class-name {
  width: auto;
}
