.chevron-asset-child-chevron-asset-child {
  gap: 20px;
  flex: 1;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.chevron-asset-child-chevron-asset-child:hover {
  background-color: var(--dl-color-default-maininteractive);
}
.chevron-asset-child-logoethereum {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  justify-content: center;
}
.chevron-asset-child-image {
  width: 37px;
  height: 37px;
  align-self: center;
  object-fit: cover;
}
.chevron-asset-child-frame1171275268 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.chevron-asset-child-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.chevron-asset-child-text2 {
  color: #000000;
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.chevron-asset-child-text3 {
  display: inline-block;
}
.chevron-asset-child-text4 {
  display: inline-block;
}





@media(max-width: 550px) {
  .chevron-asset-child-chevron-asset-child {
    gap: var(--dl-space-space-unit);
  }
}
