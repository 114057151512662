.content-small-title-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.content-small-title-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-transform: uppercase;
  text-decoration: none;
}
.content-small-titleroot-class-name {
  height: auto;
}
.content-small-titleroot-class-name1 {
  height: auto;
}
.content-small-titleroot-class-name2 {
  height: auto;
}
.content-small-titleroot-class-name3 {
  height: auto;
}
.content-small-titleroot-class-name4 {
  height: auto;
}
.content-small-titleroot-class-name5 {
  height: auto;
}
.content-small-titleroot-class-name6 {
  height: auto;
}
.content-small-titleroot-class-name7 {
  height: auto;
}
.content-small-titleroot-class-name8 {
  height: auto;
}
.content-small-titleroot-class-name9 {
  height: auto;
}
.content-small-titleroot-class-name10 {
  height: auto;
}
.content-small-titleroot-class-name11 {
  height: auto;
}
.content-small-titleroot-class-name12 {
  height: auto;
}
.content-small-titleroot-class-name13 {
  height: auto;
}
.content-small-titleroot-class-name14 {
  height: auto;
}
.content-small-titleroot-class-name15 {
  height: auto;
}
.content-small-titleroot-class-name16 {
  height: auto;
}
.content-small-titleroot-class-name17 {
  height: auto;
}
.content-small-titleroot-class-name18 {
  height: auto;
}
.content-small-titleroot-class-name19 {
  height: auto;
}
.content-small-titleroot-class-name20 {
  height: auto;
}
.content-small-titleroot-class-name21 {
  height: auto;
}
.content-small-titleroot-class-name22 {
  height: auto;
}
.content-small-titleroot-class-name23 {
  height: auto;
}
.content-small-titleroot-class-name24 {
  height: auto;
}
.content-small-titleroot-class-name25 {
  height: auto;
}
.content-small-titleroot-class-name26 {
  height: auto;
}
.content-small-titleroot-class-name27 {
  height: auto;
}
.content-small-titleroot-class-name28 {
  height: auto;
}
.content-small-titleroot-class-name29 {
  height: auto;
}
.content-small-titleroot-class-name30 {
  height: auto;
}
.content-small-titleroot-class-name31 {
  height: auto;
}
.content-small-titleroot-class-name32 {
  height: auto;
}
.content-small-titleroot-class-name33 {
  height: auto;
}
.content-small-titleroot-class-name34 {
  height: auto;
}
.content-small-titleroot-class-name35 {
  height: auto;
}
.content-small-titleroot-class-name36 {
  height: auto;
}
.content-small-titleroot-class-name37 {
  height: auto;
}
.content-small-titleroot-class-name38 {
  height: auto;
}
.content-small-titleroot-class-name39 {
  height: auto;
}
.content-small-titleroot-class-name40 {
  height: auto;
}
.content-small-titleroot-class-name41 {
  height: auto;
}
.content-small-titleroot-class-name42 {
  height: auto;
}
.content-small-titleroot-class-name43 {
  height: auto;
}
.content-small-titleroot-class-name44 {
  height: auto;
}
.content-small-titleroot-class-name45 {
  height: auto;
}
.content-small-titleroot-class-name46 {
  height: auto;
}
.content-small-titleroot-class-name47 {
  height: auto;
}
.content-small-titleroot-class-name48 {
  height: auto;
}
.content-small-titleroot-class-name49 {
  height: auto;
}
.content-small-titleroot-class-name50 {
  height: auto;
}
.content-small-titleroot-class-name51 {
  height: auto;
}
.content-small-titleroot-class-name52 {
  height: auto;
}
.content-small-titleroot-class-name53 {
  height: auto;
}
.content-small-titleroot-class-name54 {
  height: auto;
}
.content-small-titleroot-class-name55 {
  height: auto;
}
.content-small-titleroot-class-name56 {
  height: auto;
}
.content-small-titleroot-class-name57 {
  height: auto;
}
.content-small-titleroot-class-name58 {
  height: auto;
}
.content-small-titleroot-class-name59 {
  height: auto;
}
.content-small-titleroot-class-name60 {
  height: auto;
}
.content-small-titleroot-class-name61 {
  height: auto;
}
.content-small-titleroot-class-name62 {
  height: auto;
}
.content-small-titleroot-class-name63 {
  height: auto;
}
.content-small-titleroot-class-name64 {
  height: auto;
}
.content-small-titleroot-class-name65 {
  height: auto;
}
.content-small-titleroot-class-name66 {
  height: auto;
}
.content-small-titleroot-class-name67 {
  height: auto;
}
.content-small-titleroot-class-name68 {
  height: auto;
}
.content-small-titleroot-class-name70 {
  height: auto;
}
.content-small-titleroot-class-name71 {
  height: auto;
}
.content-small-titleroot-class-name72 {
  height: auto;
}
.content-small-titleroot-class-name73 {
  height: auto;
}
.content-small-titleroot-class-name74 {
  height: auto;
}
.content-small-titleroot-class-name75 {
  height: auto;
}
.content-small-titleroot-class-name76 {
  height: auto;
}
.content-small-titleroot-class-name77 {
  height: auto;
}
.content-small-titleroot-class-name78 {
  height: auto;
}
.content-small-titleroot-class-name79 {
  height: auto;
}
.content-small-titleroot-class-name80 {
  height: auto;
}
.content-small-titleroot-class-name81 {
  height: auto;
}
.content-small-titleroot-class-name82 {
  height: auto;
}
@media(max-width: 1200px) {
  .content-small-title-container {
    height: auto;
  }
  .content-small-title-text {
    font-family: Gotham-Bold;
  }
}
