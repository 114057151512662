.progress-bar-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.progress-bar-progress {
  width: 100%;
  height: 21px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-color: rgba(137, 80, 250, 0.3);
}
.progress-bar-complete {
  top: 0px;
  left: 0px;
  width: 20%;
  height: 21px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: rgba(137, 80, 250, 1);
}
