.stakeway-navigation-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
.stakeway-navigation-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.stakeway-navigation-navlink1 {
  display: contents;
}
.stakeway-navigation-image1 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.stakeway-navigation-image2 {
  width: 32px;
  display: none;
  object-fit: cover;
}
.stakeway-navigation-links-block {
  width: 100%;
  display: flex;
  overflow: scroll;
  align-items: center;
  justify-content: space-between;
}
.stakeway-navigation-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stakeway-navigation-links {
  gap: 32px;
  display: flex;
  align-items: center;
}
.stakeway-navigation-component1 {
  text-decoration: none;
}
.stakeway-navigation-navlink2 {
  display: contents;
}
.stakeway-navigation-container3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.stakeway-navigation-text1 {
  color: var(--dl-color-default-maintext);
  font-size: 14px;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}
.stakeway-navigation-text1:hover {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
}
.stakeway-navigation-component2 {
  text-decoration: none;
}
.stakeway-navigation-component3 {
  text-decoration: none;
}
.stakeway-navigation-component4 {
  text-decoration: none;
}
.stakeway-navigation-component5 {
  text-decoration: none;
}
.stakeway-navigation-component6 {
  text-decoration: none;
}
.stakeway-navigation-sign-in {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.stakeway-navigation-component7 {
  text-decoration: none;
}
.stakeway-navigation-link {
  display: contents;
}
.stakeway-navigation-action {
  gap: 16px;
  display: flex;
  padding: 8px 16px;
  box-shadow: 0px 4px 4px 0px rgba(137, 80, 250, 0.25) ;
  box-sizing: content-box;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 32px;
  text-decoration: none;
  background-color: rgba(39, 48, 60, 1);
}
.stakeway-navigation-text2 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  text-decoration: none;
}
.stakeway-navigation-vector {
  width: 13px;
  height: 8px;
}


@media(max-width: 550px) {
  .stakeway-navigation-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
  }
  .stakeway-navigation-image1 {
    display: none;
  }
  .stakeway-navigation-image2 {
    width: 32px;
    display: flex;
  }
  .stakeway-navigation-content {
    gap: var(--dl-space-space-unit);
    justify-content: unset;
  }
  .stakeway-navigation-sign-in {
    gap: var(--dl-space-space-unit);
  }
}
