.asset-page-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: a36e54d8-df5a-4450-8347-3a09649e8b3e;
}
.asset-page-content {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.asset-page-hero {
  flex: initial;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  justify-content: space-between;
}
.asset-page-container2 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.asset-page-heading {
  color: rgb(0,0,0);
  width: 287px;
  height: 144px;
  font-size: 64px;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
.asset-page-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
}
.asset-page-button {
  fill: var(--dl-color-default-secondarytext);
  color: var(--dl-color-default-secondarytext);
  cursor: pointer;
  align-self: center;
  font-family: "Gotham-Medium";
  margin-left: auto;
  border-style: hidden;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-color: var(--dl-color-default-maininteractive);
}
@media(max-width: 550px) {
  .asset-page-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .asset-page-container2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
}
