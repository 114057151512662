.navigation-link-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.navigation-link-text {
  color: var(--dl-color-default-maintext);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  transition: 0.3s;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
.navigation-link-text:hover {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
}
