.blog-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
.blog-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.blog-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.blog-component12 {
  text-decoration: none;
}
.blog-component13 {
  text-decoration: none;
}
.blog-row1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.blog-component14 {
  text-decoration: none;
}
.blog-component15 {
  text-decoration: none;
}
.blog-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.blog-navlink {
  display: contents;
}
.blog-component16 {
  text-decoration: none;
}
.blog-component17 {
  text-decoration: none;
}
.blog-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.blog-component18 {
  text-decoration: none;
}
.blog-component19 {
  text-decoration: none;
}
.blog-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.blog-component20 {
  text-decoration: none;
}
.blog-component21 {
  text-decoration: none;
}
.blog-component22 {
  text-decoration: none;
}
.blog-row2 {
  gap: var(--dl-space-space-twounits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
@media(max-width: 550px) {
  .blog-container2 {
    width: 100%;
  }
  .blog-container3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-row1 {
    width: 100%;
    flex-direction: column;
  }
  .blog-container4 {
    flex-direction: column;
  }
  .blog-container5 {
    flex-direction: column;
  }
  .blog-container6 {
    flex-direction: column;
  }
  .blog-row2 {
    width: 100%;
    flex-direction: column;
  }
}
