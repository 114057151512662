.card-promo-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 550px;
  align-items: flex-start;
  flex-direction: column;
}
.card-promo-content {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 56px 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  max-height: 400px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
.card-promo-text1 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
.card-promo-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 217px;
  display: flex;
  overflow: visible;
  align-items: flex-start;
  flex-direction: row;
}
.card-promo-text2 {
  color: var(--dl-color-default-maintext);
  width: 50%;
  height: auto;
  z-index: 1;
  font-size: 16px;
  min-width: 200px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.card-promo-image {
  width: 80%;
  margin-top: -64px;
  object-fit: cover;
}
@media(max-width: 550px) {
  .card-promo-content {
    max-height: 350px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .card-promo-container2 {
    height: 183px;
  }
  .card-promo-image {
    margin-top: -48px;
  }
}
