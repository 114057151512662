.input-field-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.input-field-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
.input-field-textinput {
  width: 100%;
  font-size: 20px;
  box-shadow: 0px 24px 12px -20px rgba(137, 80, 250, 0.3);
  font-family: "Gotham-Medium";
  line-height: 28px;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-default-maininteractive);
  border-width: 2px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
@media(max-width: 550px) {
  .input-field-text {
    font-family: Gotham-Medium;
  }
}
