.announcement-small-container {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.announcement-small-content {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  justify-content: space-between;
  background-color: rgba(137, 80, 250, 0.20000000298023224);
}
.announcement-small-message {
  gap: 4px;
  width: auto;
  display: flex;
  align-items: center;
}
.announcement-small-lightning-bolt {
  width: 16px;
  height: 16px;
}
.announcement-small-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 400px;
  line-height: 18px;
  font-stretch: normal;
  text-overflow: ellipsis;
  letter-spacing: 0.25px;
}
.announcement-small-collapse-arrow {
  width: 16px;
  height: 16px;
}
.announcement-smallroot-class-name {
  width: auto;
}
.announcement-smallroot-class-name1 {
  width: auto;
}
.announcement-smallroot-class-name2 {
  width: auto;
}
.announcement-smallroot-class-name3 {
  width: auto;
}
.announcement-smallroot-class-name4 {
  width: auto;
}
.announcement-smallroot-class-name5 {
  width: auto;
}
.announcement-smallroot-class-name6 {
  width: auto;
}
.announcement-smallroot-class-name7 {
  width: auto;
}
.announcement-smallroot-class-name8 {
  width: auto;
}
.announcement-smallroot-class-name9 {
  width: auto;
}
.announcement-smallroot-class-name10 {
  width: auto;
}
.announcement-smallroot-class-name11 {
  width: auto;
}
.announcement-smallroot-class-name12 {
  width: auto;
}
.announcement-smallroot-class-name13 {
  width: auto;
}
.announcement-smallroot-class-name14 {
  width: auto;
}
.announcement-smallroot-class-name15 {
  width: auto;
}
.announcement-smallroot-class-name16 {
  width: auto;
}
.announcement-smallroot-class-name17 {
  width: auto;
}
@media(max-width: 1200px) {
  .announcement-smallroot-class-name {
    width: auto;
  }
  .announcement-smallroot-class-name1 {
    width: auto;
  }
  .announcement-smallroot-class-name2 {
    width: auto;
  }
  .announcement-smallroot-class-name3 {
    width: auto;
  }
  .announcement-smallroot-class-name4 {
    width: auto;
  }
  .announcement-smallroot-class-name5 {
    width: auto;
  }
  .announcement-smallroot-class-name6 {
    width: auto;
  }
  .announcement-smallroot-class-name7 {
    width: auto;
  }
  .announcement-smallroot-class-name8 {
    width: auto;
  }
  .announcement-smallroot-class-name9 {
    width: auto;
  }
  .announcement-smallroot-class-name10 {
    width: auto;
  }
  .announcement-smallroot-class-name11 {
    width: auto;
  }
  .announcement-smallroot-class-name12 {
    width: auto;
  }
  .announcement-smallroot-class-name13 {
    width: auto;
  }
  .announcement-smallroot-class-name14 {
    width: auto;
  }
  .announcement-smallroot-class-name15 {
    width: auto;
  }
  .announcement-smallroot-class-name16 {
    width: auto;
  }
  .announcement-smallroot-class-name17 {
    width: auto;
  }
}
@media(max-width: 550px) {
  .announcement-small-message {
    width: 100%;
  }
  .announcement-small-text {
    width: auto;
  }
}
