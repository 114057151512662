.navigation-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
.navigation-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.navigation-flip-card {
  width: 44px;
  height: 40px;
  perspective: 1000px;
  text-decoration: none;
}
.navigation-flip-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.navigation-flip-card-front {
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.navigation-frame4814901 {
  width: 43px;
  height: 40px;
}
.navigation-flip-card-back {
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.navigation-frame4814902 {
  width: 43px;
  height: 40px;
}
.navigation-links-block {
  width: 100%;
  display: flex;
  overflow: scroll;
  align-items: center;
  justify-content: space-between;
}
.navigation-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation-links {
  gap: 32px;
  display: flex;
  align-items: center;
}
.navigation-component1 {
  text-decoration: none;
}
.navigation-link1 {
  display: contents;
}
.navigation-component2 {
  text-decoration: none;
}
.navigation-component3 {
  text-decoration: none;
}
.navigation-navlink1 {
  display: contents;
}
.navigation-component4 {
  text-decoration: none;
}
.navigation-navlink2 {
  display: contents;
}
.navigation-component5 {
  text-decoration: none;
}
.navigation-link2 {
  display: contents;
}
.navigation-component6 {
  text-decoration: none;
}
.navigation-sign-in {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.navigation-link3 {
  display: contents;
}
.navigation-component7 {
  text-decoration: none;
}
.navigation-link4 {
  display: contents;
}
.navigation-component8 {
  text-decoration: none;
}
.navigationroot-class-name {
  max-width: 1200px;
}
.navigationroot-class-name1 {
  max-width: 1200px;
}
.navigationroot-class-name2 {
  max-width: 1200px;
}
.navigationroot-class-name3 {
  max-width: 1200px;
}
.navigationroot-class-name4 {
  max-width: 1200px;
}
.navigationroot-class-name5 {
  max-width: 1200px;
}
.navigationroot-class-name6 {
  max-width: 1200px;
}
.navigationroot-class-name7 {
  max-width: 1200px;
}
.navigationroot-class-name8 {
  max-width: 1200px;
}
.navigationroot-class-name9 {
  max-width: 1200px;
}
.navigationroot-class-name11 {
  max-width: 1200px;
}
.navigationroot-class-name13 {
  max-width: 1200px;
}
.navigationroot-class-name16 {
  max-width: 1200px;
}
.navigationroot-class-name17 {
  max-width: 1200px;
}
.navigationroot-class-name18 {
  max-width: 1200px;
}
.navigationroot-class-name19 {
  max-width: 1200px;
}
.navigationroot-class-name20 {
  max-width: 1200px;
}
.navigationroot-class-name21 {
  max-width: 1200px;
}
.navigationroot-class-name22 {
  max-width: 1200px;
}
.navigationroot-class-name23 {
  max-width: 1200px;
}
.navigationroot-class-name24 {
  max-width: 1200px;
}
.navigationroot-class-name25 {
  max-width: 1200px;
}
.navigationroot-class-name26 {
  max-width: 1200px;
}
.navigationroot-class-name27 {
  max-width: 1200px;
}
.navigationroot-class-name28 {
  max-width: 1200px;
}
.navigationroot-class-name29 {
  max-width: 1200px;
}
.navigationroot-class-name30 {
  max-width: 1200px;
}
.navigationroot-class-name31 {
  max-width: 1200px;
}
.navigationroot-class-name32 {
  max-width: 1200px;
}
.navigationroot-class-name33 {
  max-width: 1200px;
}
.navigationroot-class-name34 {
  max-width: 1200px;
}
.navigationroot-class-name35 {
  max-width: 1200px;
}
.navigationroot-class-name36 {
  max-width: 1200px;
}
.navigationroot-class-name37 {
  max-width: 1200px;
}
.navigationroot-class-name38 {
  max-width: 1200px;
}
.navigationroot-class-name39 {
  max-width: 1200px;
}
.navigationroot-class-name40 {
  max-width: 1200px;
}
.navigationroot-class-name41 {
  max-width: 1200px;
}
.navigationroot-class-name42 {
  max-width: 1200px;
}
@media(max-width: 550px) {
  .navigation-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
  }
  .navigation-content {
    gap: var(--dl-space-space-unit);
    justify-content: unset;
  }
  .navigation-sign-in {
    gap: var(--dl-space-space-unit);
  }
}
