.footer-link-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.footer-link-text {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  transition: 0.3s;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-link-text:hover {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
}
@media(max-width: 1200px) {
  .footer-link-text {
    font-family: Gotham-Medium;
  }
}
