.form-back-container {
  width: auto;
  cursor: pointer;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.form-back-arrowright {
  width: 24px;
  height: 24px;
}

