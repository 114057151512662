.privacy-policy-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
.privacy-policy-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-hero {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.privacy-policy-description {
  gap: var(--dl-space-space-halfunit);
  width: 421px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-text10 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 18px;
  font-stretch: normal;
}
.privacy-policy-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.privacy-policy-link {
  display: contents;
}
.privacy-policy-container4 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.privacy-policy-text11 {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.privacy-policy-container5 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-text12 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
.privacy-policy-testimonials {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media(max-width: 1200px) {
  .privacy-policy-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .privacy-policy-container4 {
    height: auto;
  }
  .privacy-policy-text11 {
    font-family: Gotham-Bold;
  }
  .privacy-policy-testimonials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .privacy-policy-testimonials {
    flex-direction: column;
  }
}
@media(max-width: 550px) {
  .privacy-policy-container1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .privacy-policy-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .privacy-policy-description {
    width: 100%;
  }
  .privacy-policy-container3 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .privacy-policy-text11 {
    fill: var(--dl-color-default-maininteractive);
    color: var(--dl-color-default-maininteractive);
    font-size: 18px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
  .privacy-policy-testimonials {
    flex-direction: column;
  }
}
