.card-product-highlight-container1 {
  width: 100%;
  height: 240px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.card-product-highlight-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 12px 32px -20px rgba(137, 80, 250, 1) ;
  align-items: flex-end;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
}
.card-product-highlight-texts {
  gap: 8px;
  display: flex;
  flex-grow: 1;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.card-product-highlight-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
.card-product-highlight-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 400px;
  line-height: 20px;
  font-stretch: normal;
  letter-spacing: 0.25px;
}
@media(max-width: 550px) {
  .card-product-highlight-container1 {
    height: auto;
  }
}
